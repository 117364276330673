/**
 * Constants
 */
const LEFT_PAGE = "LEFT"
const RIGHT_PAGE = "RIGHT"

module.exports.LEFT_PAGE = LEFT_PAGE
module.exports.RIGHT_PAGE = RIGHT_PAGE

/**
 * Custom function to create range. Includes the 'to' item
 * @param {Int} from
 * @param {Int} to
 * @param {Int} step
 */
const range = (from, to, step = 1) => {
  let i = from
  const range = []

  while (i <= to) {
    range.push(i)
    i += step
  }

  return range
}

module.exports.range = range

/**
 * Get array of paged items to be used for pagination
 * @param {Int} totalPages
 * @param {Int} currentPage
 * @param {Int} pageNeighbors
 */
const pageNumbers = (totalPages, currentPage, pageNeighbors) => {
  /**
   * totalNumbers: the total page numbers to show on the control
   * totalBlocks: totalNumbers + 2 to cover for the left(<) and right(>) controls
   */

  let totalNumbers = 1
  totalNumbers += Math.max(0, Math.min(currentPage - 1, pageNeighbors))
  totalNumbers += Math.max(0, Math.min(totalPages - currentPage, pageNeighbors))

  const totalBlocks = totalNumbers + 2

  if (totalPages > totalBlocks) {
    const startPage = Math.max(2, currentPage - pageNeighbors)
    const endPage = Math.min(totalPages - 1, currentPage + pageNeighbors)

    let pages = range(startPage, endPage)

    /**
     * hasLeftSpill: has hidden pages to the left
     * hasRightSpill: has hidden pages to the right
     * spillOffset: number of hidden pages either to the left or to the right
     */
    const hasLeftSpill = startPage > 2
    const hasRightSpill = totalPages - endPage > 1
    const spillOffset = totalNumbers - (pages.length + 1)

    switch (true) {
      // handle: (1) < {5 6} [7] {8 9} (10)
      case hasLeftSpill && !hasRightSpill: {
        const extraPages = range(startPage - spillOffset, startPage - 1)
        pages = [LEFT_PAGE, ...extraPages, ...pages]
        break
      }

      // handle: (1) {2 3} [4] {5 6} > (10)
      case !hasLeftSpill && hasRightSpill: {
        const extraPages = range(endPage + 1, endPage + spillOffset)
        pages = [...pages, ...extraPages, RIGHT_PAGE]
        break
      }

      // handle: (1) < {4 5} [6] {7 8} > (10)
      case hasLeftSpill && hasRightSpill:
      default: {
        pages = [LEFT_PAGE, ...pages, RIGHT_PAGE]
        break
      }
    }

    return [1, ...pages, totalPages]
  }

  return range(1, totalPages)
}

module.exports.pageNumbers = pageNumbers
