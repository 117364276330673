import React from "react"
import PropTypes from "prop-types"
import { Container } from "react-bootstrap"
import { translate, KEYS } from "../../translations"

import styles from "./BlogHead.module.scss"

const BlogHead = props => {
  const { className, locale } = props

  return (
    <div className={[styles.blogHead, className].join(" ")}>
      <Container>
        <div className={styles.backgroundImage}>
          <h1 className={styles.title}>{translate(KEYS.BLOG_TITLE, locale)}</h1>
          <h2 className={styles.subtitle}>
            {translate(KEYS.BLOG_SUBTITLE, locale)}
          </h2>
        </div>
      </Container>
    </div>
  )
}

BlogHead.propTypes = {
  className: PropTypes.string,
  locale: PropTypes.string
}

export default BlogHead
