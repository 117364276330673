import { LOCALES } from "./utilities"

interface NewsletterForm {
  portalId: string
  formId: string
}

const LocaleForms: Record<string, NewsletterForm> = {
  [LOCALES.FR]: {
    portalId: "2856475",
    formId: "79d2a54e-175d-405a-b175-73b975a0e255"
  },
  [LOCALES.EN]: {
    portalId: "2856475",
    formId: "a244d30e-7c39-47c3-90bf-a776eb7c629d"
  },
  [LOCALES.EN_US]: {
    portalId: "2856475",
    formId: "a244d30e-7c39-47c3-90bf-a776eb7c629d"
  },
  [LOCALES.ES]: {
    portalId: "2856475",
    formId: "00372455-44e7-4077-93fd-0bfbaaf58cd2"
  },
  [LOCALES.DE]: {
    portalId: "2856475",
    formId: "34bd117b-3896-41f6-b4f9-395f78f0cca9"
  },
  [LOCALES.IT]: {
    portalId: "2856475",
    formId: "1deda191-c92b-4dda-854d-e38fd21faad1"
  },
  [LOCALES.NL]: {
    portalId: "2856475",
    formId: "21a50380-99ba-4328-8274-73152c20ce81"
  }
}

export const getNewsletterForm = (
  locale: string
): NewsletterForm | undefined => {
  return LocaleForms[locale]
}
