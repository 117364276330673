import React from "react"
import PropTypes from "prop-types"
import t from "../../translations"
import PageHeadForm from "../../components/PageHead1/PageHeadForm"
import { getNewsletterForm } from "../../utilities/newsletterForms"
import styles from "./NewsletterBlock.module.scss"

const NewsletterBlock = props => {
  const { locale } = props

  const classNames = [styles.newsletterBlock]
  const { formId, portalId } = getNewsletterForm(locale)
  if (props.className) {
    classNames.push(props.className)
  }

  return (
    <div className={classNames.join(" ")}>
      <h2 className="h3">
        {t.translate(t.KEYS.SUBSCRIBE_TO_NEWSLETTER, locale)}
      </h2>
      <p>{t.translate(t.KEYS.SUBSCRIPTION_BLOCK_TEXT, locale)}</p>
      <PageHeadForm
        portalId={portalId}
        formId={formId}
        formStyle="inline"
        locale={props.locale}
      />
      {/* <form className="form-inline">
        <div className={"form-group" + " " + styles.inputGroup}>
          <input
            type="text"
            className="form-control me-sm-3 mb-3 mb-sm-0"
            placeholder="Email address"
          />
          <button className="btn btn-primary form-control" type="submit">
            Subscribe
          </button>
        </div>
      </form> */}
    </div>
  )
}

NewsletterBlock.propTypes = {
  className: PropTypes.string,
  locale: PropTypes.string.isRequired
}

export default NewsletterBlock
