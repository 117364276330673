import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  LEFT_PAGE,
  RIGHT_PAGE,
  pageNumbers
} from "../../utilities/paginationHelpers"

const Pager = ({ className, totalPages, currentPage, linkGenerator }) => {
  const [windowWidth, setWindowWidth] = useState(undefined)

  const windowResizeHandler = event => {
    setWindowWidth(window.innerWidth)
  }

  useEffect(() => {
    if (typeof window !== "undefined") {
      windowResizeHandler()

      window.addEventListener("resize", windowResizeHandler)

      return () => {
        window.removeEventListener("resize", windowResizeHandler)
      }
    }
  }, [])

  if (totalPages <= 1) return null

  let sidePages = 1

  if (windowWidth >= 768) {
    sidePages = 4
  } else if (windowWidth >= 576) {
    sidePages = 3
  } else if (windowWidth >= 375) {
    sidePages = 2
  }

  const pages = pageNumbers(totalPages, currentPage, sidePages)

  return (
    <div className={className}>
      <ul className="pagination justify-content-center">
        {pages.map((page, index) => {
          let pageNumber = page
          if (pageNumber === LEFT_PAGE) {
            pageNumber = currentPage - 1
          }
          if (pageNumber === RIGHT_PAGE) {
            pageNumber = currentPage + 1
          }

          const path = linkGenerator(pageNumber)

          if (page === LEFT_PAGE) {
            return (
              <li key={index} className="page-item">
                <a className="page-link" href={path} aria-label="Previous">
                  <span aria-hidden="true">&laquo;</span>
                  <span className="sr-only">Previous</span>
                </a>
              </li>
            )
          }

          if (page === RIGHT_PAGE) {
            return (
              <li key={index} className="page-item">
                <a className="page-link" href={path} aria-label="Next">
                  <span aria-hidden="true">&raquo;</span>
                  <span className="sr-only">Next</span>
                </a>
              </li>
            )
          }

          return (
            <li
              key={index}
              className={`page-item${currentPage === page ? " active" : ""}`}
            >
              <a className="page-link" href={path}>
                {page}
              </a>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

Pager.propTypes = {
  className: PropTypes.string,
  totalPages: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  linkGenerator: PropTypes.func.isRequired
}

export default Pager
