import { useStaticQuery, graphql } from "gatsby"
import _ from "lodash"

export const useContentfulBlogPostCategory = locale => {
  const { allContentfulBlogPostCategory } = useStaticQuery(graphql`
    query {
      allContentfulBlogPostCategory(
        sort: { fields: name, order: ASC }
        filter: { slug: { ne: null }, name: { ne: null } }
      ) {
        nodes {
          ...blogPostCategoryShortFields
          thumbnail: image {
            ...assetFields
            gatsbyImageData(
              layout: FIXED
              width: 102
              height: 102
              quality: 100
            )
          }
        }
      }
    }
  `)

  const categories = _.get(allContentfulBlogPostCategory, "nodes", [])

  const filteredCategories = categories.filter(category => {
    return category.node_locale === locale
  })

  return { categories: filteredCategories }
}
