import React from "react"
import PropTypes from "prop-types"
import { graphql, Link, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import styles from "./CategorySectionItem.module.scss"

const CategorySectionItem = props => {
  const { thumbnail, title, url } = props
  const data = useStaticQuery(graphql`
    query {
      placeholder: file(relativePath: { eq: "category-placeholder.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 102, height: 102, quality: 100)
        }
      }
    }
  `)

  return (
    <div className={styles.categorySectionItem}>
      <Link to={url} className={"text-center text-decoration-none"}>
        <GatsbyImage
          className={styles.thumbnail}
          image={
            thumbnail
              ? thumbnail.gatsbyImageData
              : data.placeholder.childImageSharp.gatsbyImageData
          }
          alt={title}
        />
        <h3
          className={[
            "h6 mx-2 mt-3 mb-0 text-center text-dark",
            styles.categoryTitle
          ].join(" ")}
        >
          {title}
        </h3>
      </Link>
    </div>
  )
}

CategorySectionItem.propTypes = {
  thumbnail: PropTypes.object,
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired
}

export default CategorySectionItem
